import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SearchPanel from "../../components/search-panel/search-panel.component";
import RightOutput from "../../components/right-output/right-output.component";
import Guidance from "../../components/guidance/guidance.component";
import Disclaimer from "../../components/disclaimer/disclaimer.component";
import DepartmentInfo from "../../components/department-info/department-info.component";
import SpecifyPanel from "../../components/specify-panel/specify-panel.component";
import Loading from "../../components/loading/loading-component";
import { Form, FormControl, InputGroup, Button } from 'react-bootstrap';
import { setSelectedResultIndex } from '../../redux/search/search.actions';
import { Container, Row } from 'react-bootstrap';

import './search-page.scss';

function SearchPage({ showGeography, searchTerm, loading, currentSearches,setSelectedResultIndex }) {

  const selectResultIndex = (event) => {
    setSelectedResultIndex( +event.target.value)
  }

  return (
    <Fragment>
      {showGeography === 0 && searchTerm === "" && !loading &&
        <Container fluid className="d-flex background" >

          <Row className="align-items-start justify-content-center flex-fill">
            <SearchPanel />
          </Row>
        </Container>
      }

      {showGeography > 1 && showGeography < 4 &&
        <Container fluid className="d-flex background" >

          <Row xs={1} sm={1} md={1} lg={2} className="align-items-start justify-content-center flex-fill">
            <SearchPanel />
            <RightOutput />
          </Row>
        </Container>

      }

      {showGeography === 1 && !loading && (
        <Container fluid className="d-flex background results" >

          <Fragment>
            {
              currentSearches && currentSearches.length > 1 && 
              <Row xs={1} sm={1} md={1} lg={2} className="mt-4 ml-0" style={{paddingRight:"44px"}}>
                <FormControl
                  onChange={ selectResultIndex }
                  //onKeyPress={this.onEnter}
                  //value={this.state.selection}
                  // placeholder={ this.props.translate("specify-panel.placeholder") }
                  as="select"
                  aria-label="Select a result"
                >
                  <Fragment>
                    {
                      currentSearches.map((search, index) => {
                        return (
                          <option key={search._id} value={index}>{search.city}, {search.state}</option>
                        )
                      })
                    }
                  </Fragment>
                </FormControl>

              </Row>}
            <Row xs={1} sm={1} md={1} lg={2} className="mb-4 mt-4">
              {window.innerWidth >= 800 &&
                <Fragment>
                  <DepartmentInfo />
                  <Guidance />
                </Fragment>}

              {window.innerWidth < 800 &&
                <Fragment>
                  <Guidance />
                  <DepartmentInfo />
                </Fragment>}

            </Row>
            <Disclaimer />
          </Fragment>
        </Container>
      )}

      {showGeography === 4 && !loading &&
        <Container fluid className="d-flex background" >

          <Row className="align-items-start justify-content-center flex-fill">
            <SpecifyPanel />
          </Row>
        </Container>
      }

      {loading && showGeography <= 1 &&
        (<Container fluid className="d-flex background">
          <Row className="align-items-start justify-content-center flex-fill">
            <Loading />
          </Row>
        </Container>)}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  showGeography: state.search.showGeography,
  searchTerm: state.search.searchTerm,
  loading: state.search.loading,
  currentSearches: state.search.currentSearch,
})


export default connect(mapStateToProps, {setSelectedResultIndex})(SearchPage);