//export const ROOT_URL = "http://localhost:9090/api"
// export const ROOT_URL = "https://covid19travelinfo.cdc.gov/api"
 //export const ROOT_URL = "https://tbcarefinder-dev.cdc.gov/api"
 //export const ROOT_URL = "https://tbcarefinder-uat.cdc.gov/api"
 export const ROOT_URL = "https://tbcarefinder.cdc.gov/api"

// Converts an comma-separated string into a Regex expression
export const toRegex = (str) => {

    if(str === undefined){
        return([])
    } else {

        var array = str.split(",");

        for (var i = 0; i < array.length; i++) {
            array[i] = array[i].trim();
        }
        var regex = array.join("|");

        return(regex);
    }
}
